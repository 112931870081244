import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class NavigationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  isShow = false;
 
  toggleMenu() {
    this.isShow = !this.isShow;
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.service';

import { SwiperOptions } from 'swiper';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

    constructor(public mainService: MainService) { }

    config: SwiperOptions = {
        effect: 'fade', 
        autoplay: {
            delay: 4000
        }
    }

    ngOnInit(): void {
    }

}

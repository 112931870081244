<div class="ps-sm-5 py-5 d-inline-flex gap-3 align-items-center flex-column">
    <h1 class="m-0">Izaberite Stream</h1>
    <div class="d-flex flex-row" id="streams">

        <div class="d-flex align-items-center p-1">
            <input type="checkbox" [(ngModel)]='qualityMedium' [ngModelOptions]='{standalone: true}' (change)="onCheckboxChange('medium', qualityMedium)" class="form-check-input sv" id="sv1" data-type="stream_aac" value="aac64"
                name="stream_value" checked>
            <div class="ms-2 d-flex flex-column">
                <span class="fw-bold">AAC</span>
                <span class="text-uppercase">64 kbit/s stereo</span>
            </div>
        </div>

        <div class="d-flex align-items-center p-1">
            <input type="checkbox" [(ngModel)]='qualityHigh' [ngModelOptions]='{standalone: true}' (change)="onCheckboxChange('high', qualityHigh)"  class="form-check-input sv" id="sv3" data-type="stream_mp3" value="mp3"
                name="stream_value">
            <div class="ms-2 d-flex flex-column">
                <span class="fw-bold">MP3</span>
                <span class="text-uppercase">128 kbit/s stereo</span>
            </div>
        </div>
    </div>
</div>
<i class="ri-settings-3-line settings-dark medium-btn-size me-4" (click)='mainService.toggleStreamQualitySettings()'></i>
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './layout/home/home.component';
import { FrequenciesComponent } from './layout/frequencies/frequencies.component';
import { ContactComponent } from './layout/contact/contact.component';
import { MainService } from './shared/services/main.service';
import { SmallPlayBubbleComponent } from './layout/common/small-play-bubble/small-play-bubble.component';
import { LargePlayBubbleComponent } from './layout/common/large-play-bubble/large-play-bubble.component';
import { NavigationComponent } from './layout/common/navigation/navigation.component';
import { StreamQualitySettingsComponent } from './layout/common/stream-quality-settings/stream-quality-settings.component';
import { AudioPlayerComponent } from './layout/common/audio-player/audio-player.component';
import { StreamSelectorComponent } from './layout/common/stream-selector/stream-selector.component';
import { MainComponent } from './layout/main/main.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EmailService } from './shared/services/email.service';
import {
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { environment } from '../environments/environment';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        FrequenciesComponent,
        ContactComponent,
        SmallPlayBubbleComponent,
        LargePlayBubbleComponent,
        NavigationComponent,
        StreamSelectorComponent,
        StreamQualitySettingsComponent,
        AudioPlayerComponent,
        MainComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxUsefulSwiperModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RecaptchaModule
    ],
    providers: [
        MainService, EmailService,
        {
          provide: RECAPTCHA_SETTINGS,
          useValue: {
            siteKey: environment.recaptchaSiteKeyV2,
          } as RecaptchaSettings,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

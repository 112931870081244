import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-audio-player',
    templateUrl: './audio-player.component.html',
    styleUrls: ['./audio-player.component.css'],
    encapsulation: ViewEncapsulation.None

})
export class AudioPlayerComponent implements OnInit {

    @ViewChild('audioPlayer') public audioPlayer: ElementRef;

    public audioStreamStateChangedListener = null;
    public audioVolumeChangedListener = null;
    public audioStreamSource = null;

    public streamTypePlay = 'play-me';
    public streamTypeParty = 'party';
    public streamTypeRock = 'rock';
    public streamTypeSoft = 'soft';
    public streamTypeUrban = 'urban';
    
    public mediumQualityExtension = 'aac';
    public highQualityExtension = 'mp3';



    constructor(public mainService: MainService) { }

    ngOnInit(): void {
        this.audioStreamStateChangedListener = this.mainService.audioStreamStateChanged.subscribe((playStream) => {
            if (playStream === true) {
                this.startAudioStream();
            } else {
                this.stopAudioStream();
            }
        });

        this.audioVolumeChangedListener = this.mainService.audioVolumeChanged.subscribe((volumeValue) => {
            this.setVolume(volumeValue);
        })
    }

    ngOnDestroy() {
        if (this.audioStreamStateChangedListener) {
            this.audioStreamStateChangedListener.unsubscribe();
        }
        if (this.audioVolumeChangedListener) {
            this.audioVolumeChangedListener.unsubscribe();
        }
    }

    startAudioStream() {
        this.audioStreamSource = this.getStreamSource();
        this.audioPlayer.nativeElement.load();

        setTimeout(() => {
            this.audioPlayer.nativeElement.play();
            this.setVolume(this.mainService.currentAudioVolume);
        }, 250);
    }

    stopAudioStream() {
        this.audioPlayer.nativeElement.load();
        this.audioPlayer.nativeElement.pause();
    }
   
    setVolume(volume) {
        if (volume > 0) {
            volume = volume / 100;
        }

        this.audioPlayer.nativeElement.volume = volume;
    }

    getStreamSource() {
        var quality = localStorage.getItem('stream_quality');
        var streamType = this.mainService.getStreamType();

        if (!quality) {
            quality = 'medium';
        }

        return this.constructStreamUrl(streamType, quality);
    }

    constructStreamUrl(type, quality) {
        switch(type) {
            case 'party':
                type = this.streamTypeParty;
                break;
            case 'rock':
                type = this.streamTypeRock;
                break;
            case 'soft':
                type = this.streamTypeSoft;
                break;
            case 'urban':
                type = this.streamTypeUrban;
                break;
            case 'play-me':
            default:
                type = this.streamTypePlay;
        }

        var extension = null;

        switch(quality) {
            case 'high':
                extension = this.highQualityExtension;
                break;
            case 'medium':
            default:
                extension = this.mediumQualityExtension;
                break;
        }

        return `${environment.streamUrl}${type}.${extension}`;
    }
}

<div class="position-relative d-flex justify-content-center">
    <!--Frequencies Section-->
    <div class="container">
        <div class="row d-flex justify-content-center default-radius overflow-hidden main-default main-slide">
            <div class="col-9 py-5 mt-lg-0 col-lg-5 d-flex flex-column justify-content-center">
                <h1 class="sf-title mt-4">Frekvencije</h1>
                <div class="mb-1 fw-bold"><span>107,30 MHz</span> - Bar</div>
                <div class="mb-1 fw-bold"><span>87,90 MHz</span> - Bijelo Polje</div>
                <div class="mb-1 fw-bold"><span>88,20 MHz</span> - Budva</div>
                <div class="mb-1 fw-bold"><span>100,40 MHz</span> - Cetinje</div>
                <div class="mb-1 fw-bold"><span>103,00 MHz</span> - Kolašin</div>
                <div class="mb-1 fw-bold"><span>93,40 MHz</span> - Kotor</div>
                <div class="mb-1 fw-bold"><span>103,40 MHz</span> - Mojkovac</div>
                <div class="mb-1 fw-bold"><span>93,90 MHz</span> - Nikšić</div>
                <div class="mb-1 fw-bold"><span>100,70 MHz</span> - Plav</div>
                <div class="mb-1 fw-bold"><span>102,30 MHz</span> - Pljevlja</div>
                <div class="mb-1 fw-bold"><span>102,50 MHz</span> - Danilovgrad</div>
                <div class="mb-1 fw-bold"><span>102,50 MHz</span> - Podgorica</div>
                <div class="mb-1 fw-bold"><span>105,00 MHz</span> - Rožaje</div>
                <div class="mb-1 fw-bold"><span>104,60 MHz</span> - Tivat</div>
                <div class="mb-1 fw-bold"><span>104,60 MHz</span> - Herceg Novi</div>
                <div class="mb-1 fw-bold"><span>90,90 MHz</span> - Šavnik</div>
                <div class="mb-1 fw-bold"><span>88,40 MHz</span> - Ulcinj</div>
                <div class="mb-1 fw-bold"><span>100,70 MHz</span> - Žabljak</div>
            </div>
            <div class="col-9 col-lg-5 d-flex justify-content-center align-items-center">
                <img class="w-75 img-fluid" src="./assets/images/Radio.png" alt="Radio Image" />
            </div>
            <div class="mb-5 py-5 py-lg-4"></div>
        </div>
    </div>
    <!--Frequencies Section End-->
</div>

<div class="row radius-100 bg-purple-light justify-content-evenly align-items-center my-3 mx-auto p-0">
    <div class="row np-center px-0">
        <app-large-play-bubble id="play-player"
            class="col-lg-6 bg-yellow align-items-center justify-content-between  radius-100"
            style='display: flex;' *ngIf='mainService.showStreamQualitySettings === false'>
        </app-large-play-bubble>
        <app-stream-quality-settings id="play-player-settings"
            class="col-lg-6 bg-player-settings align-items-center   justify-content-between radius-100"
            style='display: flex;' [ngStyle]='{"display": "flex"}' *ngIf='mainService.showStreamQualitySettings === true'>
        </app-stream-quality-settings>

        <app-stream-selector class="col-lg-6 px-4"></app-stream-selector>
    </div>
</div>
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.service';

@Component({
    selector: 'app-small-play-bubble',
    templateUrl: './small-play-bubble.component.html',
    styleUrls: ['./small-play-bubble.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SmallPlayBubbleComponent implements OnInit {

    constructor(public mainService: MainService) { }

    public volumeValue = null;
    public minVolume = 0;
    public maxVolume = 100;

    ngOnInit(): void {
        this.volumeValue = this.mainService.currentAudioVolume;
    }

    volumeSliderChanged(event) {
        this.volumeValue = event.target.value;
        this.mainService.audioVolumeChanged.emit(this.volumeValue);
        this.mainService.currentAudioVolume = this.volumeValue;
    }
}

<div class="d-flex-inline align-items-center text-center px-5">
    <div class="row justify-content-between align-items-center py-3">
        <div class="col-lg-6">
            <h1>Play <br> Extra</h1>
            <h2 class="fs-2 purple-dark fw-bolder">Izaberi svoju muziku</h2>
        </div>

        <div class="col-lg-5 my-auto">
            <div class="d-flex justify-content-between align-items-center flex-wrap pt-3">
                <div class="play-party play-channel-container">
                    <img src="./assets/images/play-stream-party.png" alt="Party" id="partyStation" data-title="Party" class="playerStation" [ngClass]="{'station-off': currentStreamType !== 'party'}" data-id-station="6" (click)="selectStreamType('party')">
                    <p>PLAY <b>Party</b></p>
                </div>
                <div class="play-party play-channel-container">
                    <img src="./assets/images/play-stream-rock.png" alt="Rock" id="rockStation" data-title="Rock" class="playerStation" [ngClass]="{'station-off': currentStreamType !== 'rock'}" data-id-station="5" (click)="selectStreamType('rock')">
                    <p>PLAY <b>Rock</b></p>
                </div>
                <div class="play-party play-channel-container">
                    <img src="./assets/images/play-stream-soft.png" alt="Soft" id="softStation" data-title="Soft" class="playerStation" [ngClass]="{'station-off': currentStreamType !== 'soft'}" data-id-station="7" (click)="selectStreamType('soft')">
                    <p>PLAY <b>Soft</b></p>
                </div>
                <div class="play-party play-channel-container">
                    <img src="./assets/images/play-stream-urban.png" alt="Urban" id="urbanStation" data-title="Urban" class="playerStation" [ngClass]="{'station-off': currentStreamType !== 'urban'}" data-id-station="8" (click)="selectStreamType('urban')">
                    <p>PLAY <b>Urban</b></p>
                </div>
                <div class="play-play play-channel-container">
                    <img src="./assets/images/play-stream-play.png" alt="Play" id="playStation" data-title="Play" class="playerStation" [ngClass]="{'station-off': currentStreamType !== 'play'}" data-id-station="9" (click)="selectStreamType('play')">
                    <p>PLAY <b>Play</b></p>
                </div>
            </div>
        </div>
    </div>
</div>

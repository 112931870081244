import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.service';

@Component({
    selector: 'app-frequencies',
    templateUrl: './frequencies.component.html',
    styleUrls: ['./frequencies.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class FrequenciesComponent implements OnInit {

    constructor(public mainService: MainService) { }

    ngOnInit(): void {
    }

}

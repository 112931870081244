<!-- Navigation Bar -->


<nav class="navbar fixed-top navbar-expand-xl sticky-top bg-purple container px-0 mb-4">
    <div class="container-fluid p-0">
        <a class="navbar-brand" href="/index.html">
            <img src="/assets/images/playmontenegro_logo.png" alt="Play radio logo">
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleMenu()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div [hidden]="!isShow" class="navbar-collapse justify-content-between align-items-center text-center"
            id="navbarSupportedContent">
            <ul class="navbar-nav mx-lg-auto mb-2 mb-lg-0 gap-1 text-center">
                <li class="nav-item mx-2">
                    <a class="nav-link" aria-current="page" routerLink='/'>Home/Plejer</a>
                </li>
                <li class="nav-item mx-2">
                    <a class="nav-link" aria-current="page" routerLink='/frequencies'>Frekvencije</a>
                </li>
                <li class="nav-item mx-2">
                    <a class="nav-link" aria-current="page" routerLink='/contact'>Kontakt</a>
                </li>
            </ul>

            <app-small-play-bubble></app-small-play-bubble>
        </div>
    </div>
</nav>
<!-- End of Navigation Bar -->
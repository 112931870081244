
<!DOCTYPE html>
<html lang="en" class="no-js">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">

    <title>PLAY Radio - Montenegro</title>

    <script type="module">
        document.documentElement.classList.remove('no-js'); document.documentElement.classList.add('js');
    </script>

    <link rel="stylesheet" href="/assets/css/styles.css">
    <!-- Montserrat Font -->
    <link rel="preload" href="/assets/fonts/Montserrat-Regular.ttf" as="font" type="font/ttf" crossorigin>

    <meta name="description" content="Page description">
    <meta property="og:title" content="Unique page title - My Site">
    <meta property="og:description" content="Page description">
    <meta property="og:image" content="https://www.mywebsite.com/image.jpg">
    <meta property="og:image:alt" content="Image description">
    <meta property="og:locale" content="en_GB">
    <meta property="og:type" content="website">
    <meta name="twitter:card" content="summary_large_image">
    <meta property="og:url" content="https://www.mywebsite.com/page">
    <!-- <link rel="canonical" href="https://www.mywebsite.com/page"> -->

    <!-- PWA Web Manifest -->
    <link rel="apple-touch-icon" sizes="180x180" href="webmanifest/apple-touch-icon.png">
    <link rel="icon" type="image/png" sizes="32x32" href="webmanifest//favicon-32x32.png">
    <link rel="icon" type="image/png" sizes="16x16" href="webmanifest//favicon-16x16.png">
    <link rel="manifest" href="webmanifest//site.webmanifest">

    <!-- Remixicons -->
    <link href="/assets/fonts/remixicons/remixicon.css" rel="stylesheet">

    <!-- Bootstrap v5
  <link href="assets/boostrap/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous"> -->
    <!-- <link href='assets/bootstrap/css/bootstrap.min.css'> -->

</head>

<body class="bg-purple">

    <section id="main-section" class="container">

        <app-audio-player></app-audio-player>
        <app-navigation></app-navigation>


        <router-outlet></router-outlet>

        <!-- Main Container End -->


        <!-- Start of Footer -->
        <footer id="footer">
            <div class="container py-5">
                <div class="row">
                    <div class="col d-flex flex-column flex-md-row align-items-center justify-content-center m-2">
                        <a href="/index.html">
                            <img width="96" height="46.5" src="/assets/images/playmontenegro_logo.png"
                                alt="Footer Logo">
                        </a>
                        <p class="ms-md-4 mb-0 mt-4 mt-md-0 text-center">Sva prava zadržana <span class="fw-bold">{{ currentYear }} ©
                                - Play Radio Montenegro</span></p>
                    </div>
                </div>
            </div>
        </footer>
        <!-- Footer End -->

    </section>

    <!-- End of .container -->
    <script type="text/javascript" src="/assets/js/script.js" type="module"></script>
    <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9
  i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
    <!-- <script src="/assets/boostrap/js/bootstrap.min.js" integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF" crossorigin="anonymous"></script> -->
</body>

</html>

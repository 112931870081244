import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {

  constructor(public mainService: MainService) { }

  public currentYear: number = null;

  ngOnInit(): void {
      this.currentYear = new Date().getFullYear();
      this.mainService.startSocketListener();
  }

}

<!-- Player Section Modified-->
<div class="px-sm-5 py-5 d-inline-flex gap-3 align-items-center unselectable">
    <span id="big_play_button" (click)='mainService.toggleStreamPlayback()'
        class="btn btn-outline-light py-2 rounded-circle d-inline-flex hw-100 d-flex justify-content-center align-items-center border-5 me-3">
        <i class="ri-play-fill big-play-btn" [ngClass]="{'ri-play-fill': mainService.isStreamPlaying === false, 'ri-pause-fill': mainService.isStreamPlaying === true}"></i> 
    </span>
    <div>
        <span class="fs-3 fw-bold yellow-dark m-0">Now playing</span>
        <h1 class="now_artist m-0">{{ mainService.currentArtist }}</h1>
        <p class="now_title text-capitalize m-0">{{ mainService.currentSong }}</p>
    </div>
</div>
<i class="ri-settings-3-line yellow-dark medium-btn-size me-4" (click)='mainService.toggleStreamQualitySettings()'></i>

<!-- Player End -->
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.service';

@Component({
    selector: 'app-stream-selector',
    templateUrl: './stream-selector.component.html',
    styleUrls: ['./stream-selector.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class StreamSelectorComponent implements OnInit {

    constructor(public mainService: MainService) { }

    public currentStreamType = null;

    ngOnInit(): void {
        this.currentStreamType = this.mainService.getStreamType();
    }

    selectStreamType(streamType) {
        this.currentStreamType = streamType;
        this.mainService.setStreamType(streamType);

        this.mainService.stopSocketListener();

        setTimeout(() => {
            this.mainService.startSocketListener();
        }, 100);

        if (this.mainService.isStreamPlaying) {
            this.mainService.stopStreamPlayback();
    
            setTimeout(() => {
                this.mainService.startStreamPlayback();
            }, 2500);
        } else {
            this.mainService.startStreamPlayback();
        }
    }
}

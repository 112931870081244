<!-- Main Container -->
<div class="position-relative d-flex justify-content-center">
    <!--Contact Section-->
    <div class="container">
        <div class="row d-flex justify-content-center default-radius overflow-hidden bg-blue">
            <div class="col-lg-1"></div>
            <div class="col-9 mt-5 mt-lg-0 col-lg-5 d-flex flex-column justify-content-center pe-lg-5">
                <h1 class="sf-title">Kontakt</h1>
                <form id="" [formGroup]='contactForm' (ngSubmit)='submitForm()'>
                    <div class="row">
                        <div class="col-lg-6 mt-2">
                            <input class="contact_field" type="text" placeholder="Ime" name="ime" formControlName='name' />
                        </div>
                        <div class="col-lg-6 mt-2">
                            <input class="contact_field" type="email" placeholder="E-mail" name="email" formControlName='email'/>
                        </div>
                    </div><br>
                    <div class="row mb-1 mx-1">
                        <textarea class="contact_text_area py-5 px-4" id="w3review" name="w3review" rows="3" formControlName='message'
                            placeholder="Poruka"></textarea>
                    </div>
                    <div class="row mb-2 mt-3 mx-1">
                      <re-captcha (resolved)="onCaptchaResolved()" class="p-0"></re-captcha>
                    </div>
                    <div class="row mt-4 justify-content-end px-3">
                        <input class="contact_button" type="submit" value="Pošalji!">
                    </div>
                    <div class="mb-1 mt-4 mx-1 download-container">
                        Obrazac vlasničke strukture emitera: <a href="assets/downloads/Obrazac-vlasnicke-strukture-emitera.pdf" target="_blank" class="download-link">Preuzmi</a> <i class="ri-file-download-line download-icon"></i>
                    </div>
                </form>
                <div *ngIf='responseMessage !== ""' class='message mt-4 alert alert-warning'>{{ responseMessage }}</div>
            </div>
            <div class="col-9 col-lg-5">
                <img class="w-100 img-fluid mt-5" src="./assets/images/Holding.png" alt="Holding Phone Image" />
            </div>
        </div>
    </div>
    <!--Contact Section-->
</div>
<!-- Main Container End -->

<div class="row radius-100 bg-purple-light justify-content-evenly align-items-center my-3 mx-auto p-0">
    <div class="row np-center px-0">
        <app-large-play-bubble id="play-player"
            class="col-lg-6 bg-yellow align-items-center justify-content-between  radius-100"
            style='display: flex;' *ngIf='mainService.showStreamQualitySettings === false'>
        </app-large-play-bubble>
        <app-stream-quality-settings id="play-player-settings"
            class="col-lg-6 bg-player-settings align-items-center   justify-content-between radius-100"
            style='display: flex;' [ngStyle]='{"display": "flex"}' *ngIf='mainService.showStreamQualitySettings === true'>
        </app-stream-quality-settings>

        <app-stream-selector class="col-lg-6 px-4"></app-stream-selector>
    </div>
</div>

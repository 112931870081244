import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmailService } from 'src/app/shared/services/email.service';



@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {

    constructor(public mainService: MainService, public formBuilder: FormBuilder, public emailService: EmailService) { }

    public contactForm: any;
    public responseMessage: string = '';
    public captchaResolved: boolean = false;


    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.contactForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            message: ['', Validators.required]
        });
    }

    onCaptchaResolved() {
        this.captchaResolved = true;
    }

    submitForm() {
        if (!this.captchaResolved) {
            this.responseMessage = 'Greška! Molimo vas da popunite reCAPTCHA-u!';
            return;
        }

        var formData = this.contactForm.value;
        var name = formData['name'];
        var email = formData['email'];
        var message = formData['message'];

        if (this.contactForm.controls['email'].valid === false && email !== '') {
            this.responseMessage = 'Greška! Nevažeća e-mail adresa.';
            return;
        }

        if (!this.contactForm.valid) {
            this.responseMessage = 'Greška! Morate ispuniti svako polje.';
            return;
        }

        this.emailService.sendMessageRequest(name, email, message).subscribe(response => {
            if (response.message) {
                this.responseMessage = response.message;
            } else {
                this.responseMessage = 'Uspešno ste poslali pismo.';
            }
        });
    }






}

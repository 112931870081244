import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()

export class EmailService {

    constructor(public http: HttpClient) {
    }

    sendMessageRequest(name: string, email: string, message: string): Observable<any> {
        var postUrl = 'https://www.playradio.rs/ajax/sendEmailMe.php';

        var headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

        var response = null;

        var params = {
            'action': 'contact',
            'name': name,
            'email': email,
            'message': message
        };
        var options = { headers: headers };

        return this.http.post<any>(postUrl, {'params': params}, options)
    }
}
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.service';

@Component({
    selector: 'app-stream-quality-settings',
    templateUrl: './stream-quality-settings.component.html',
    styleUrls: ['./stream-quality-settings.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class StreamQualitySettingsComponent implements OnInit {

    constructor(public mainService: MainService) { }

    public qualityMedium: boolean = true;
    public qualityHigh: boolean = false;

    ngOnInit(): void {
        this.initQualityCheckboxes();
    }

    initQualityCheckboxes() {
        var quality = localStorage.getItem('stream_quality');

        if (!quality) {
            quality = 'medium';
        }
        
        switch (quality) {
            case 'medium':
                this.qualityMedium = true;
                this.qualityHigh = false;
                break;
            case 'high':
                this.qualityMedium = false;
                this.qualityHigh = true;
                break;
        }
    }

    onCheckboxChange(setting, value) {
        switch (setting) {
            case 'low':
                this.qualityMedium = false;
                this.qualityHigh = false;
                break;
            case 'medium':
                this.qualityMedium = true;
                this.qualityHigh = false;
                break;
            case 'high':
                this.qualityMedium = false;
                this.qualityHigh = true;
                break;
        }

        localStorage.setItem('stream_quality', setting);

        if (this.mainService.isStreamPlaying) {
            this.mainService.stopStreamPlayback();

            setTimeout(() => {
                this.mainService.startStreamPlayback();
            }, 2500);
        } else {
            this.mainService.startStreamPlayback();
        }
    }

}

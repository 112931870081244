import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.service';

@Component({
    selector: 'app-large-play-bubble',
    templateUrl: './large-play-bubble.component.html',
    styleUrls: ['./large-play-bubble.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class LargePlayBubbleComponent implements OnInit {

    constructor(public mainService: MainService) { }

    ngOnInit(): void {
    }

}

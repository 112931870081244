<!-- Main Container -->
<div class="position-relative d-flex justify-content-center">
    <!--Swiper Section-->
    <swiper class='swiper mySwiper' [config]='config'>
        <div class="swiper-wrapper">

            <div class="swiper-slide">
                <div
                    class="radius-100 main-slide h-725 d-flex align-items-center overflow-hidden my-3 position-relative">
                    <div class="container h-100">
                        <div class="row d-flex justify-content-center overflow-hidden bg-gradient-blue h-100">
                            <div
                                class="col-9 mt-5 mt-lg-0 col-lg-5 d-flex flex-column justify-content-center align-items-center">
                                <h1 class="text-center display-2">Najbolja<br />muzika<br />sada!</h1>
                            </div>
                            <div class="col-lg-7 d-flex align-items-end">
                                <img class="w-100 img-fluid" src="./assets/images/DJ.png" alt="Najbolja Muzika Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="swiper-slide">
                <div
                    class="radius-100 main-slide h-725 mx-auto d-flex align-items-center overflow-hidden my-3 position-relative">
                    <div class="container h-100">
                        <div class="row d-flex justify-content-center overflow-hidden bg-gradient-peach h-100">
                            <div
                                class="col-9 mt-5 mt-lg-0 col-lg-5 d-flex flex-column justify-content-center align-items-center">
                                <h1 class="text-center display-2">Najbolja<br />muzika<br />sada!</h1>
                            </div>
                            <div class="col-lg-7 d-flex align-items-end">
                                <img class="w-100 img-fluid" src="./assets/images/DJ2.png"
                                    alt="Najbolja Muzika Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </swiper>
    <!--Swiper Section End-->
</div>

<div class="row radius-100 bg-purple-light justify-content-evenly align-items-center my-3 mx-auto p-0">
    <div class="row np-center px-0">
        <app-large-play-bubble id="play-player"
            class="col-lg-6 bg-yellow align-items-center justify-content-between  radius-100"
            style='display: flex;' *ngIf='mainService.showStreamQualitySettings === false'>
        </app-large-play-bubble>
        <app-stream-quality-settings id="play-player-settings"
            class="col-lg-6 bg-player-settings align-items-center   justify-content-between radius-100"
            style='display: flex;' [ngStyle]='{"display": "flex"}' *ngIf='mainService.showStreamQualitySettings === true'>
        </app-stream-quality-settings>

        <app-stream-selector class="col-lg-6 px-4"></app-stream-selector>
    </div>
</div>